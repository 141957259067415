<template>
  <v-layout wrap class="justify-center">
    <v-flex xs12 sm8 md4>
      <v-form v-model="valid" ref="login" lazy-validation>
        <v-card class="elevation-4 mt-5 mx-2">
          <v-toolbar dark color="yellow darken-2" flat>
            <v-toolbar-title>{{ $t('Sign In') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field v-model="user.email" :label="$t('E-mail')" ref="email" prepend-icon="mdi-email" class="mx-3 mt-4"
                          :rules="rules.email" required :loading="loading"
            />
            <v-text-field v-model="user.password" :label="$t('Password')" prepend-icon="mdi-lock" class="mx-3"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required, rules.min]" @click:append="showPassword = !showPassword"
                          :type="showPassword ? 'text' : 'password'" required :loading="loading"
                          :hint="$t('At least 8 characters')"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link to="/reset-password-request" class="mb-4 mt-1">{{ $t("Forgot password?") }}</router-link>
            <v-btn color="primary" class="mb-4 mt-1 mx-4 white--text" :loading="loading" :disabled="loading"
                   depressed @click.stop="validate"
            >{{ $t('Sign In') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      valid: false,
      user: {
        email: '',
        password: ''
      },
      rules: {
        required: value => !!value || this.$t('Required'),
        min: v => (v && v.length >= 8) || this.$t('Min 8 characters'),
        email: [
          v => !!v || this.$t('Required'),
          v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid')
        ]
      },
      showPassword: false,
      loading: false
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login'
    }),
    validate () {
      this.loading = true
      if (!this.$refs['login'].validate()) {
        return this.loading = false
      }
      this.login(this.user)
        .then(() => {
          this.$router.push('/')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

